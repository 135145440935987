import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"

import "./blog-post.scss"
import Img from "gatsby-image"

export default function BlogPostTemplate({ data }: { data: any }) {
  const { markdownRemark: post, allImageContent } = data

  const img =
    post.frontmatter.img &&
    allImageContent.edges.find(
      (element: any) => element.node.fluid.originalName === post.frontmatter.img
    )

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={
          post.frontmatter.description
            ? post.frontmatter.description
            : "Free to do list & time tracker for programmers & designers with build-in Jira & Github Integrations. Download for free or hack it for yourself."
        }
      />

      {img && <Img fluid={img.node.fluid} className="blog-post-header-img" />}
      <div className="container">
        <article className="blog-post">
          <h1>{post.frontmatter.title}</h1>
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </article>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        img
      }
    }
    allImageContent: allImageSharp {
      edges {
        node {
          fluid(maxWidth: 1900) {
            originalName
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  }
`
